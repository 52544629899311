import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { medWrapper } from "../../styles/helpers"

const GallerySection = styled.section`
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 10rem;

  @media (min-width: 768px) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .galleyWrap {
    width: 100%;

    @media (min-width: 768px) {
      columns: 2 350px;
      column-gap: 1rem;
    }

    @media (min-width: 1025px) {
      columns: 2 300px;
      column-gap: 1rem;
    }
  }

  .galleryImage {
    display: inline-block;
    width: 100%;
    margin: 0 1rem 1rem 0;
  }
`

const Gallery = ({ gallery }) => {
  return (
    <GallerySection>
      <div className="wrapper">
        <div className="galleyWrap">
          {gallery.map((img, index) => {
            return (
              <div className="galleryImage" key={index}>
                <Img
                  fluid={img?.localFile?.childImageSharp?.fluid}
                  alt={img?.alt_text}
                />
              </div>
            )
          })}
        </div>
      </div>
    </GallerySection>
  )
}

export default Gallery
