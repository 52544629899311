import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import {
  standardWrapper,
  colors,
  B1Black,
  H3Black,
  buttonThree,
} from "../../styles/helpers"

import Gallery from "./Gallery"
import { Link } from "gatsby"

const BuildersInfoSection = styled.section`
  background-color: ${colors.colorAlt};
  padding-bottom: 2rem;
  border-bottom: 2rem solid ${colors.colorAccent};

  .wrapper {
    ${standardWrapper};
  }

  .intro {
    width: 100%;
    padding: 2.5rem 0;
    text-align: center;

    p {
      ${B1Black};
    }
  }

  .logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 95rem;
    margin: auto;
    margin-bottom: 5rem;

    .logo {
      display: flex;
      align-items: center;
      width: calc(20% - 1rem);
      padding: 0 1rem;
      margin: 0.5rem 0.5rem;
      transition: all 0.3s ease-in;
      background-color: ${colors.white};
      box-shadow: 0rem 0rem 0rem 0rem rgba(0, 0, 0, 0.5);
      cursor: pointer;

      @media (min-width: 768px) {
        width: calc(16.66% - 1rem);
        margin: 1rem 0.5rem;
      }

      .gatsby-image-wrapper {
        width: 100%;
      }

      &.active-builder {
        box-shadow: 0.75rem 0.75rem 1.2rem 0.4rem rgba(0, 0, 0, 0.5);
      }
    }
  }

  .wrapperBuild {
    ${standardWrapper};
    background-color: ${colors.white};

    @media (max-width: 769px) {
      max-width: calc(100% - 2rem);
    }
  }

  .builders {
    ${standardWrapper};
    position: relative;
    width: 100%;
    height: 100%;
  }

  .details {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    opacity: 0;
    visibility: hidden;

    @media (min-width: 768px) {
    }

    &__logo {
      width: calc(100% - 15rem);

      @media (min-width: 768px) {
        width: calc(25% - 5rem);
        margin-right: 5rem;
      }

      @media (min-width: 768px) {
        width: calc(25% - 10rem);
        margin-right: 10rem;
      }
    }

    &__content {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(75% - 2.5rem);
        margin-left: 2.5rem;
      }

      @media (min-width: 768px) {
        width: calc(75% - 2.5rem);
        margin-left: 2.5rem;
      }
    }

    h2 {
      ${H3Black};
    }

    p {
      ${B1Black};
    }

    &__buttons {
      padding-bottom: 5rem;

      a {
        ${buttonThree};
        display: inline-block;
        &:last-of-type {
          margin-top: 2rem;
        }

        @media (min-width: 768px) {
          &:last-of-type {
            margin-top: 0;
            margin-left: 2rem;
          }
        }
      }
    }

    &__gallery {
      display: flex;
      justify-content: center;
      margin-top: 2.5rem;

      &--image {
        @media (min-width: 768px) {
          width: calc(33.33% - 2rem);
          margin: 1rem;
        }
      }
    }

    &.active-builder {
      opacity: 1;
      visibility: visible;
    }
  }
`

const BuildersInfo = ({ buildersInfo, location }) => {
  const introText = buildersInfo.acf._coo_buifo_intro_text
  const builders = buildersInfo.acf._coo_buifo_builders

  builders.sort(function (a, b) {
    var textA = a.name.toUpperCase()
    var textB = b.name.toUpperCase()
    return textA < textB ? -1 : textA > textB ? 1 : 0
  })

  const defaultFirstBuilder = builders[0].name.split(" ").join("").toLowerCase()
  const [activeBuilder, changeBuilder] = useState(defaultFirstBuilder)

  useEffect(() => {
    const allBuilders = document.querySelectorAll(".details")
    allBuilders.forEach(builder => (builder.style.height = "auto"))
    const buildContainer = document.querySelector(".builders")
    buildContainer.style.height = "auto"
    const activeBuilder = document.querySelector(".details.active-builder")
    buildContainer.style.height = activeBuilder.scrollHeight + "px"
  }, [activeBuilder])

  useEffect(() => {
    const cameFrom = location.search.split("=")[1]

    if(cameFrom) {
      changeBuilder(cameFrom)
    }


  }, [])

  

  

  return (
    <BuildersInfoSection>
      <div className="wrapper">
        <div
          className="intro"
          dangerouslySetInnerHTML={{ __html: introText }}
        />
      </div>
      <div className="logos">
        {builders.map((builder, index) => {
          const builderName = builder.name.split(" ").join("").toLowerCase()

          const isActive =
            activeBuilder === builderName ? " active-builder" : ""
          return (
            <div
              data-builder={builderName}
              className={`logo${isActive}`}
              key={index}
              onClick={() => {
                changeBuilder(builderName)
              }}
            >
              <Img
                fluid={builder.logo.localFile.childImageSharp.fluid}
                alt={builder.logo.alt_text}
              />
            </div>
          )
        })}
      </div>

      <div className="wrapperBuild">
        <div className="builders">
          {builders.map((builder, index) => {
            const { gallery } = builder
            const builderName = builder.name.split(" ").join("").toLowerCase()

            const isActive =
              activeBuilder === builderName ? " active-builder" : ""

            return (
              <div
                className={`details${isActive}`}
                data-builder={builderName}
                key={index}
              >
                <div className="details__logo">
                  <Img
                    fluid={builder.logo.localFile.childImageSharp.fluid}
                    alt={builder.logo.alt_text}
                  />
                </div>
                <div className="details__content">
                  <div>
                    <h2>{builder.name}</h2>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: builder.content }} />
                  <div className="details__buttons">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={builder.builders_website}
                    >
                      Builder's Website
                    </a>
                    <Link href="/show-homes">View Show Homes</Link>
                  </div>
                </div>

                <Gallery gallery={gallery} />
              </div>
            )
          })}
        </div>
      </div>
    </BuildersInfoSection>
  )
}

export default BuildersInfo
